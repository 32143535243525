import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DATE_PIPE_DEFAULT_TIMEZONE, registerLocaleData } from '@angular/common';
import { ErrorHandler, InjectionToken, LOCALE_ID, NgModule } from '@angular/core';
import localeEnAu from '@angular/common/locales/en-AU';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule, HttpRequest } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import Bugsnag from '@bugsnag/js';
import { DebugModule, Ngxh6ServicesModule, SYNC_CONFIG, UserServiceInterface } from '@hutsix/ngxh6';
import * as moment from 'moment-timezone';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import packageInfo from '../../package.json';

import { SharedModule } from './modules/_shared/shared.module';
import { custom_modals } from './config/modals-config';
import { role_hierarchy } from './config/users-config';
import { custom_filters, custom_forms, getFormsConfig } from './config/forms-config';
import { syncConfigFactory } from './config/sync-config';
import { getCrudsConfig } from './config/cruds-config';
import { THEMES_CONFIG } from './config/themes-config';
import { ClipboardModule } from 'ngx-clipboard';

registerLocaleData(localeEnAu);

Bugsnag.start({
    apiKey: '0fb7bb50d199f59c6bbf75108f875166',
    releaseStage: environment.env,
    appVersion: packageInfo.version + (environment.env === 'staging' ? '-staging' : ''),
});

export function errorHandlerFactory(): BugsnagErrorHandler {
    return new BugsnagErrorHandler();
}

const dbConfig: DBConfig = {
    name: 'appDb',
    version: 1,
    objectStoresMeta: [
        {
            store: 'offlineCache',
            storeConfig: { keyPath: 'ulid', autoIncrement: false },
            storeSchema: [
                { name: 'idx', keypath: ['endpoint', 'user'], options: { unique: false } },
                { name: 'endpoint', keypath: 'endpoint', options: { unique: false } },
                { name: 'user', keypath: 'user', options: { unique: false } },
                { name: 'ulid', keypath: 'ulid', options: { unique: true } },
                { name: 'data', keypath: 'data', options: { unique: false } },
            ],
        },
        {
            store: 'apiCache',
            storeConfig: { keyPath: ['endpoint', 'user'], autoIncrement: false },
            storeSchema: [
                { name: 'idx', keypath: ['endpoint', 'user'], options: { unique: true } },
                { name: 'endpoint', keypath: 'endpoint', options: { unique: false } },
                { name: 'user', keypath: 'user', options: { unique: false } },
                { name: 'timestamp', keypath: 'timestamp', options: { unique: false } },
                { name: 'data', keypath: 'data', options: { unique: false } },
            ],
        },
    ],
};

export function jwtOptionsFactory(userService: UserServiceInterface): object {
    return {
        tokenGetter: (r: HttpRequest<any>) => {
            if (r && r.headers.has('Authorization') && r.headers.get('Authorization')) {
                return r.headers.get('Authorization');
            }
            return userService.token;
        },
        allowedDomains: environment.whitelistedDomains,
        disallowedRoutes: environment.blacklistedRoutes,
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        DebugModule,
        Ngxh6ServicesModule.forRoot({
            config: {
                crudConfig: {
                    cruds: getCrudsConfig(),
                },
                formConfig: {
                    forms: getFormsConfig(),
                    customForms: custom_forms,
                    customFilters: custom_filters,
                },
                userConfig: {
                    roleHierarchy: role_hierarchy,
                },
                apiConfig: {
                    apiUrl: environment.apiUrl,
                },
                modalConfig: custom_modals,
                syncConfigProvider: {
                    provide: SYNC_CONFIG,
                    useFactory: syncConfigFactory,
                    deps: ['UserService'],
                },
                themeConfig: {
                    themes: THEMES_CONFIG,
                    default: 'default',
                },
            },
        }),
        SharedModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: ['UserService'],
            },
        }),
        ServiceWorkerModule.register('custom-service-worker.js', { enabled: true }),
        NgxIndexedDBModule.forRoot(dbConfig),
        ClipboardModule,
    ],
    providers: [
        { provide: ErrorHandler, useFactory: errorHandlerFactory },
        { provide: LOCALE_ID, useValue: 'en-AU' },
        { provide: DATE_PIPE_DEFAULT_TIMEZONE, useValue: '+0930' },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
        moment.tz.setDefault('Australia/Darwin');
    }
}
