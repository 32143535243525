import { CrudOptions } from '@hutsix/ngxh6';
import cruds_conf from '../_generated/ext_config/cruds.json';

/** Override generated CRUD values here */
const cruds_conf_overrides: Override = {};

/** Create custom CRUDS or override generated forms here */
const cruds = {
  // test: new CrudOptions({
  //   objectLabel: 'Test',
  //   apiEndpoint: '/api/v1/examples',
  // }),
};

/**
 *
 * You shouldn't need to change anything below
 *
 */
export function getCrudsConfig() {
  const c = JSON.parse(JSON.stringify(cruds_conf));
  for (const [k, v] of Object.entries(cruds_conf_overrides)) {
    if (c[k]) {
      c[k] = Object.assign(cruds_conf[k] || {}, v);
    } else {
      console.warn(`Attempt to override generated CRUD failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...c, ...cruds };
}

export function getCrud(slug: CrudKeys): CrudOptions {
  return getCrudsConfig()[slug];
}

declare global {
  type CrudKeys = keyof typeof cruds | keyof typeof cruds_conf;
}

type Override = { [Property in keyof Partial<typeof cruds_conf>]: Partial<CrudOptions> };
